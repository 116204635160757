// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_KEY: "cedi_api_key_dRbsQE1Tv0G2aeX16pxPt5GYZn7Wtwb27qs6GbbrjE5PJnFtvfm",
  JWT_KEY: "cedi_jwt_key_9q0VSCuZvTF0LtzPfVR3BgZWF7AO9816SCdT0ve3xYkRQ0dnL9r",
  CRYPTO_KEY: "cedi_crypto_key_idJB1Zq2zaYXJniPMK69XXfS0a9PKMnVvZ0DmWjG61gJyhnb",
  url: "https://cedinotarios-api.quetzalcode.com",
  url_notarios: 'https://notarios-api.zurco.com.mx/api/v2/notarios',
  user_notarios: 'usertest',
  pass_notarios: '12345678',
  api_key_notarios: '63bc29ee6a8e9fhNd1d2407d0ac0',
  firebase: {
    apiKey: "AIzaSyCDoH4-ySxYSjJimL_bFlyOk1-TmLfPCLo",
    authDomain: "coacalco-5604f.firebaseapp.com",
    projectId: "coacalco-5604f",
    storageBucket: "coacalco-5604f.appspot.com",
    messagingSenderId: "874788877767",
    appId: "1:874788877767:web:198b351efe0761097415e2",
    measurementId: "G-BBF92F67P9"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
